import React from 'react';
import axios from 'axios';

import './App.scss';
import { StoreProvider } from "./Store"
import AppContainer from "./AppContainer"

axios.defaults.withCredentials = true
function App() {

  return (
    <StoreProvider>
      <AppContainer />
    </StoreProvider>
  );
}

export default App;
