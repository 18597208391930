import React from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';

import { useStore } from "./Store"
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Auth/Login'));
const Logout = React.lazy(() => import('./views/Auth/Logout'));
const CheckAuth = React.lazy(() => import('./views/Auth/CheckAuth'));

function AppContainer() {
    const { store } = useStore()

    const isLogged = () => {
        const stateUser = store.AuthState
        if (stateUser && stateUser.isAuth) {
            return true;
        }
        return false
    }

    const PrivateRoute = ({ component: Component, onLogout, ...rest }) => (
        <Route {...rest} render={(props) => {
            return isLogged() === true
                ? <Component {...props} />
                : <Redirect to={{
                    pathname: '/CheckAuth',
                    state: { from: props.location }
                }} />
        }

        } />
    )
    return (
        <HashRouter>
            <React.Suspense fallback={loading()}>
                <Switch>
                    <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
                    <Route exact path="/CheckAuth" name="Checking Authorization..." render={props => <CheckAuth {...props} />} />
                    <Route exact path="/logout" name="Logout..." render={props => <Logout {...props} />} />
                    <PrivateRoute path="/" name="Home" component={DefaultLayout} />
                </Switch>
            </React.Suspense>
        </HashRouter>
    );
}

export default AppContainer;
