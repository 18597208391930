import React, { createContext, useContext, useState } from 'react';


export const StateContext = createContext();


export const StoreProvider = ({ children }) => {

    const [store, setStore] = useState({})

    const updateStore = (item) => {
        setStore({ ...store, ...item });
    };

    return (
        <StateContext.Provider value={{ store, updateStore }}>
            {children}
        </StateContext.Provider>
    )

}

export const useStore = () => useContext(StateContext);